// DOWNLOAD GUIDE
import { graphql } from 'gatsby';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import React, { useEffect, useState } from 'react';

import { handleGetGuideToGoingViralSubmit } from '../api';
import Footer from '../components/Footer';
import PageSEO from '../components/PageSEO';
import EmailInput from '../components/ui/EmailInput';
import { LocalizedSEO } from '../fragments';
import arrowBlack from '../images/dashed-arrow-black.svg';
import arrowWhite from '../images/dashed-arrow-white.svg';
import hookPointLogoBlack from '../images/hookPointBlack.svg';
import hookPointLogo from '../images/largeHookPointLogo.svg';
import { useGlobalState } from '../state/globalStateContext';
import {
  useActivateGoogleOptimize,
  useStoreReferrerOnLoad,
  useStoreScheduleOnceCalendarOnLoad,
  useStoreUTMParamsOnLoad,
  useTrackingData,
} from '../utils/hooks';
import { replaceNewLinesWithBr } from '../utils/sanity';
import { clsx, wrapCurlyBracedInNoBreak, wrapSquareBracketedWithEm } from '../utils/utils';
import * as styles from './GuideToGoingViralPage.module.scss';

export const query = graphql`
  query GetGtgvPageById($id: String!) {
    sanityGtgvLandingPage(id: { eq: $id }) {
      title
      text
      buttonText
      addInfoBoxAboutAuthor
      image {
        asset {
          url
          gatsbyImageData(width: 1100, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }
      lightVersionImage {
        asset {
          url
          gatsbyImageData(width: 1100, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }
      mobileImage {
        asset {
          url
          gatsbyImageData(width: 690, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }
      lightVersionMobileImage {
        asset {
          url
          gatsbyImageData(width: 690, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }
      destinationUrlOnSubmit
      conditionalDestinationsUrl {
        parameterName
        parameterValue
        destinationUrl
      }
      onSubmitKeapTagIds
      seo {
        ...SEO
      }
    }
    sanityGtgvLandingPageConfig {
      featuredInSection {
        title
        logos {
          asset {
            url
          }
        }
        lightVersionLogos {
          asset {
            url
          }
        }
      }
    }
  }
`;

interface GuideToGoingViralPageProps {
  data: {
    sanityGtgvLandingPage: {
      title: string;
      text: string;
      buttonText: string;
      addInfoBoxAboutAuthor?: boolean;
      image: {
        asset: {
          url: string;
          gatsbyImageData: IGatsbyImageData;
        };
      };
      lightVersionImage: {
        asset: {
          url: string;
          gatsbyImageData: IGatsbyImageData;
        };
      };
      mobileImage: {
        asset: {
          url: string;
          gatsbyImageData: IGatsbyImageData;
        };
      };
      lightVersionMobileImage: {
        asset: {
          url: string;
          gatsbyImageData: IGatsbyImageData;
        };
      };
      destinationUrlOnSubmit: string;
      conditionalDestinationsUrl?: Array<{
        parameterName: string;
        parameterValue: string;
        destinationUrl: string;
      }>;
      onSubmitKeapTagIds: Array<string>;
      seo: LocalizedSEO;
    };
    sanityGtgvLandingPageConfig: {
      featuredInSection: {
        title: string;
        logos: Array<{
          asset: {
            url: string;
          };
        }>;
        lightVersionLogos: Array<{
          asset: {
            url: string;
          };
        }>;
      };
    };
  };
}

const GuideToGoingViralPage = ({ data }: GuideToGoingViralPageProps): React.ReactElement => {
  const {
    title,
    text,
    buttonText,
    addInfoBoxAboutAuthor,
    image,
    lightVersionImage,
    mobileImage,
    lightVersionMobileImage,
    destinationUrlOnSubmit,
    conditionalDestinationsUrl,
    onSubmitKeapTagIds,
    seo,
  } = data.sanityGtgvLandingPage;
  const { featuredInSection } = data.sanityGtgvLandingPageConfig;

  const { theme } = useGlobalState();
  const isHidden = useActivateGoogleOptimize() || theme === null;

  useStoreUTMParamsOnLoad();
  useStoreReferrerOnLoad();
  useStoreScheduleOnceCalendarOnLoad();

  const trackingData = useTrackingData();

  function renderInfoBoxAboutAuthor(resolution: 'desktop' | 'mobile') {
    return (
      <div className={clsx(styles.authorContainer, styles[resolution])}>
        <p className={styles.author}>
          From the author of <strong>One Million Followers </strong>
          and
          <strong> Hook Point</strong>
        </p>
      </div>
    );
  }

  const [destinationUrl, setDestinationUrl] = useState(destinationUrlOnSubmit);

  useEffect(() => {
    function getDestinationUrl(): string {
      const urlParams = new URLSearchParams(window.location.search);

      const lowerCaseParams = new URLSearchParams();
      for (const [name, value] of urlParams) {
        lowerCaseParams.append(name.toLowerCase(), value.toLowerCase());
      }

      if (conditionalDestinationsUrl) {
        for (const {
          parameterName,
          parameterValue,
          destinationUrl,
        } of conditionalDestinationsUrl) {
          if (lowerCaseParams.get(parameterName.toLowerCase()) === parameterValue.toLowerCase()) {
            return destinationUrl;
          }
        }
      }

      return destinationUrlOnSubmit;
    }

    setDestinationUrl(getDestinationUrl());
  }, []);

  return (
    <div
      style={isHidden ? { opacity: 0 } : undefined}
      className={clsx(styles.container, theme === 'light' && styles.lightVersion)}
      data-theme={theme === 'light' ? 'light' : ''}
    >
      <PageSEO defaultTitle="Guide To Going Viral" pageSEO={seo} />
      <div className={styles.logo}>
        <img src={theme === 'light' ? hookPointLogoBlack : hookPointLogo} alt="" />
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.firstContainer}>
          {theme === 'dark' && (
            <div className={styles.imageContainer}>
              <GatsbyImage
                alt=""
                image={mobileImage.asset.gatsbyImageData}
                className={styles.mobileImage}
              />
              <GatsbyImage
                alt=""
                image={image.asset.gatsbyImageData}
                className={styles.desktopImage}
              />
              {addInfoBoxAboutAuthor && (
                <img src={arrowWhite} className={styles.arrow} alt=""></img>
              )}
            </div>
          )}
          {theme === 'light' && (
            <div className={styles.imageContainer}>
              <GatsbyImage
                alt=""
                image={lightVersionMobileImage.asset.gatsbyImageData}
                className={styles.mobileImage}
              />
              <GatsbyImage
                alt=""
                image={lightVersionImage.asset.gatsbyImageData}
                className={styles.desktopImage}
              />
              {addInfoBoxAboutAuthor && (
                <img src={arrowBlack} className={styles.arrow} alt=""></img>
              )}
            </div>
          )}
          {addInfoBoxAboutAuthor && renderInfoBoxAboutAuthor('desktop')}
        </div>
        <div className={styles.textContainer}>
          <h1 className={styles.title}>
            {replaceNewLinesWithBr(title, str =>
              wrapCurlyBracedInNoBreak(str, wrapSquareBracketedWithEm),
            )}
          </h1>
          <div className={styles.divider}></div>
          <p className={styles.text}>{replaceNewLinesWithBr(text)}</p>
          <div className={styles.formContainer}>
            <EmailInput
              id="form-ebook"
              buttonText={buttonText}
              onSubmit={({
                email,
                name,
                phoneNumber,
                acceptTheTerms,
                joinTheHookPointNewsletter,
              }) =>
                handleGetGuideToGoingViralSubmit(
                  name,
                  email,
                  phoneNumber,
                  destinationUrl,
                  trackingData,
                  onSubmitKeapTagIds,
                  'Receive a free guide',
                  acceptTheTerms,
                  joinTheHookPointNewsletter,
                )
              }
              withNameInput
              withPhoneNumberInput
              darkBackground={theme === 'dark'}
            />
            {addInfoBoxAboutAuthor && renderInfoBoxAboutAuthor('mobile')}
          </div>
        </div>
      </div>
      <div className={styles.featuredInSection}>
        <h2 className={styles.featuredInTitle}>{featuredInSection.title}</h2>
        <div className={styles.divider}></div>
        <div className={styles.feauredInLogosContainer}>
          {(theme === 'dark' ? featuredInSection.logos : featuredInSection.lightVersionLogos).map(
            (logo, i) => (
              <div className={styles.featuredInLogo} key={i}>
                <img src={logo.asset.url} alt="" />
              </div>
            ),
          )}
        </div>
      </div>
      <div className={styles.footer}>
        <Footer padding="0" noBackground withPageTheme />
      </div>
    </div>
  );
};

export default GuideToGoingViralPage;
