// extracted by mini-css-extract-plugin
export var arrow = "GuideToGoingViralPage-module--arrow--1de02";
export var authorContainer = "GuideToGoingViralPage-module--authorContainer--88036";
export var container = "GuideToGoingViralPage-module--container--5aebc";
export var contentContainer = "GuideToGoingViralPage-module--contentContainer--2d252";
export var desktop = "GuideToGoingViralPage-module--desktop--95330";
export var desktopImage = "GuideToGoingViralPage-module--desktopImage--3c981";
export var divider = "GuideToGoingViralPage-module--divider--6592e";
export var featuredInLogo = "GuideToGoingViralPage-module--featuredInLogo--3fa3d";
export var featuredInSection = "GuideToGoingViralPage-module--featuredInSection--18702";
export var featuredInTitle = "GuideToGoingViralPage-module--featuredInTitle--b15ba";
export var feauredInLogosContainer = "GuideToGoingViralPage-module--feauredInLogosContainer--ef71b";
export var firstContainer = "GuideToGoingViralPage-module--firstContainer--b9597";
export var footer = "GuideToGoingViralPage-module--footer--9848f";
export var formContainer = "GuideToGoingViralPage-module--formContainer--4f502";
export var imageContainer = "GuideToGoingViralPage-module--imageContainer--f63e3";
export var lightVersion = "GuideToGoingViralPage-module--lightVersion--662ac";
export var logo = "GuideToGoingViralPage-module--logo--1b17a";
export var mobile = "GuideToGoingViralPage-module--mobile--71725";
export var mobileImage = "GuideToGoingViralPage-module--mobileImage--c5bd7";
export var text = "GuideToGoingViralPage-module--text--64d8e";
export var textContainer = "GuideToGoingViralPage-module--textContainer--f0afd";
export var textStyleLargeTitle = "GuideToGoingViralPage-module--text-style-large-title--94d5b";
export var textStyleLargestTitle = "GuideToGoingViralPage-module--text-style-largest-title--90b82";
export var textStyleMediumTitle = "GuideToGoingViralPage-module--text-style-medium-title--90b74";
export var textStyleSmallTitle = "GuideToGoingViralPage-module--text-style-small-title--97d7b";
export var title = "GuideToGoingViralPage-module--title--90ea4";